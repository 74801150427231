import queryString from 'query-string';

import {
  serializeCalendarFilters,
  serializeSearchFlightFilters,
  serializeSearchReportsFilters,
} from '../../helpers/formCalendarParams';
import { catchServerError } from '../../Sentry';
import { APP_DOMAIN, IBE_BASE_URL, OPS_API_URL } from '../../constants/config';

export const getAirportsFrom = (search) => {
  return {
    airportsFromResponse: {
      url: `${OPS_API_URL}/api/airports?${queryString.stringify({ q: search })}`,
      catch: catchServerError,
    },
  };
};

export const getAirportsTo = (search) => {
  return {
    airportsToResponse: {
      url: `${OPS_API_URL}/api/airports?${queryString.stringify({ q: search })}`,
      catch: catchServerError,
    },
  };
};

export const getCalendar = (filters, domain) => {
  const body = JSON.stringify({ ...serializeCalendarFilters(filters), domain });
  return {
    calendarResponse: {
      url: `${IBE_BASE_URL}/flights/ibe-calendar`,
      method: 'POST',
      body,
      catch: (error, { response }) => {
        catchServerError(error, {
          response,
          request: { body },
        });
      },
    },
  };
};

export const searchFlights = (filters, domain, transactionId, { invalidateCache } = {}) => {
  return {
    searchFlightsResponse: {
      url: `${IBE_BASE_URL}/v2/ibe-search?${queryString.stringify(
        {
          ...serializeSearchFlightFilters(filters),
          domain,
          transactionSearchId: transactionId,
          invalidateCache: invalidateCache || false,
        },
        {
          arrayFormat: 'bracket',
        },
      )}`,
      catch: catchServerError,
      force: true,
    },
  };
};

export const loadMoreSearchFlights = async (filters, domain) => {
  const url = `${IBE_BASE_URL}/v2/ibe-search?${queryString.stringify(
    {
      ...serializeSearchFlightFilters(filters),
      domain,
    },
    {
      arrayFormat: 'bracket',
    },
  )}`;
  const response = await fetch(new Request(url));
  catchServerError(null, {
    response,
    request: { url },
  });
  return response.json();
};

export const writeSearchToReports = (SearchParamsData) => {
  const body = JSON.stringify(
    serializeSearchReportsFilters({
      ...SearchParamsData,
      domain: APP_DOMAIN,
    }),
  );

  const { userEmail } = SearchParamsData;

  sessionStorage.setItem('O&D-report-hit', JSON.stringify({ hit: true, ...(userEmail && { userEmail }) }));

  return {
    ElasticsearchResponse: {
      url: `${OPS_API_URL}/api/origin-destination/reports`,
      method: 'POST',
      body,
      catch: (error, { response }) => {
        catchServerError(error, {
          response,
          request: { body },
        });
      },
    },
  };
};

export const saveFlightKey = (flight, filters) => {
  const body = JSON.stringify({
    searchParams: { ...serializeSearchFlightFilters(filters), domain: 'travelopod' },
    flightKey: flight[0].flightKey,
  });
  return {
    saveFlightKeyResponse: {
      url: `${IBE_BASE_URL}/flight`,
      method: 'POST',
      body,
      catch: (error, { response }) => {
        catchServerError(error, {
          response,
          request: {
            body,
          },
        });
      },
    },
  };
};

export const verifyBooking = async (flight) => {
  let response = null;
  const url = `${IBE_BASE_URL}/flight/check-itinerary`;
  const body = JSON.stringify({ flight });
  try {
    response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body,
    });

    catchServerError(null, {
      response,
      request: { url },
    });

    const { code, summary } = await response.json();

    const verifyBookingError = new Error(code);
    if (code === 'PRICE_HAS_CHANGED') {
      verifyBookingError.changedPrice = summary.total.total;
    }

    return code ? { code, summary } : response;
  } catch (error) {
    catchServerError(error, {
      response,
      request: { url, body },
    });
    throw error;
  }
};

export const getInitialAirports = async (from, to) => {
  const fromUrl = getAirportsFrom(from).airportsFromResponse.url;
  const toUrl = getAirportsTo(to).airportsToResponse.url;

  try {
    const [fromResult = [], toResult = []] = await Promise.all([
      fetch(fromUrl)
        .then((r) => {
          catchServerError(null, {
            response: r,
            request: { url: fromUrl },
          });
          return r.json();
        })
        .then((r) => r.data),
      fetch(toUrl)
        .then((r) => {
          catchServerError(null, {
            response: r,
            request: { url: toUrl },
          });
          return r.json();
        })
        .then((r) => r.data),
    ]);

    const first = fromResult.find((i) => i.code === from);
    const second = toResult.find((i) => i.code === to);
    return [
      {
        ...(first && { ...first }),
      },
      {
        ...(second && { ...second }),
      },
    ];
  } catch (e) {}
};

export const refreshSearch = async (filters) => {
  const { calendarResponse } = getCalendar(filters);
  const { searchFlightsResponse } = searchFlights(filters);

  try {
    const [calendarResponseData, flightsResponseData] = await Promise.all([
      fetch(calendarResponse.url, {
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        method: calendarResponse.method,
        body: calendarResponse.body,
      }),
      fetch(searchFlightsResponse.url),
    ]);

    if (!(calendarResponseData.ok && flightsResponseData.ok)) {
      return {};
    }

    const calendar = await calendarResponseData.json();
    const flights = await flightsResponseData.json();

    return {
      calendar,
      flights,
    };
  } catch (e) {
    return {};
  }
};
